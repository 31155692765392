import { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { IconButton, Tooltip,  Spinner, Alert, AlertIcon, CloseButton, useToast, position }  from "@chakra-ui/react";
import BalanceModal from './BalanceModal.js';
import DepositModal from './DepositModal.js';
import ReactTooltip from 'react-tooltip';
const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ADDRESS = config.portfolioAddress;
const PORTFOLIO_ABI = config.portfolioAbi;


export default function Transactions() {
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [web3, setWeb3] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [contract, setContract] = useState(null);
    const [openPositions, setOpenPositions] = useState(null);
    const [perpetuals, setPerpetuals] = useState([]);
    const [amountInvested, setAmountInvested] = useState(0);
    const [currentValuation, setCurrentValuation] = useState(0);
    const [balance, setBalance] = useState(null);
    const toast = useToast();
	const [profitLoss, setProfitLoss] = useState(0);

	 // Portfolio Table Pagination
	 const [portfolioCurrentPage, setPortfolioCurrentPage] = useState(1);
	 const portfolioItemsPerPage = 7;
    useEffect(() => {
        async function init() {
            const token = localStorage.getItem("jwtToken");
            if (!token) {
                window.location.href = '/sign';
                return;
            }

            const provider = await detectEthereumProvider();
            if (!provider) {
                console.error("Please install MetaMask!");
                return;
            }

            await provider.request({ method: 'eth_requestAccounts' });
            const web3Instance = new Web3(provider);
            setWeb3(web3Instance);

			const chainId = await web3Instance.eth.getChainId();
			console.log(`Connected chain ID: ${chainId}`);
			if (chainId === 1) {
				console.log("You are connected to Ethereum Mainnet.");
			} else if (chainId === 11155111) {
				console.log("You are connected to Sepolia Testnet.");
			} else {
				console.log("You are connected to an unknown network.");
			}

            const accounts = await web3Instance.eth.getAccounts();
            if (!accounts || accounts.length === 0) {
                console.error("No accounts available.");
                return;
            }
            setAccounts(accounts);

            const contractInstance = new web3Instance.eth.Contract(PORTFOLIO_ABI, PORTFOLIO_ADDRESS);
            setContract(contractInstance);
            if (accounts.length > 0 && contractInstance) {
                getBalance(contractInstance, accounts[0]);
            }
            
        }
        init();
    }, []);

    useEffect(() => {
        if (contract && accounts.length > 0) {
            handleGetAllRecords();
            handleOpenPositions();
            getUserRecords();
        }
    }, [contract, accounts]);  // Ensure records are fetched when contract and accounts are ready

    const handleGetAllRecords = async () => {
        if (!contract || accounts.length === 0) {
            console.error("Contract not initialized or no accounts available.");
            return;
        }
        try {
            const userAddy = accounts[0];
            const userRecords = await contract.methods.searchRecordsByAddress(userAddy,0).call();
            //console.log('Search by Addy', userRecords);
            setRecords(userRecords);
        } catch (error) {
            console.error("Error searching records:", error);
        }
    };

    const handleOpenPositions = async () => {
		if (!contract || accounts.length === 0) {
			console.error("Contract not initialized or no accounts available.");
			return;
		}
		try {
			const userAddy = accounts[0];
			const userPositions = await contract.methods.getOpenPositions(userAddy).call();
			
			if (!userPositions || userPositions.length === 0) {
			console.log("No open positions found.");
			return;
			}
		
			console.log('Open Positions', userPositions);
		
			const fetchedPerpetuals = await contract.methods.getAllPerpetuals().call();
			setPerpetuals(fetchedPerpetuals);
			setOpenPositions(userPositions);
		} catch (error) {
			console.error("Error searching records:", error);
		}
	};

	const navigate = useNavigate();

	const handleClosePositions = async (position, index) => {
	navigate('/openDetail', {state: {position, index}});
	};


    const getBalance = async (contractInstance, account) => {
        try {
            const balance = await contractInstance.methods.getNetBalance(account).call();
            setBalance(parseFloat(Web3.utils.fromWei(balance.toString(), 'ether')).toFixed(2));
        } catch (error) {
            console.error("Error getting balance:", error);
        }
    };

	const getUserRecords = async () => {
		if (!contract) {
			console.error("Contract not initializeds", contract);
			return;
		}
		try {
			const userAddy = accounts[0];
			const userRecords = await contract.methods.searchRecordsByAddress(userAddy,0).call();
			console.log(`search by address`, userRecords);
			const totalCurrentValuation = userRecords.reduce((total, record) => {
				const valuation = Number(record.currentValuation || '0');
				return total + valuation;
			  }, 0);
			setCurrentValuation(totalCurrentValuation.toFixed(2));
	
			const totalAmountInvested = userRecords.reduce((total, record) => {
				const valuation = Number(record.amountInvested || '0');
				return total + valuation;
			  }, 0);
			setAmountInvested(totalAmountInvested);
		} catch (error) {
			console.error("Error searching records:", error);
		}
	};
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast({
                title: 'Copied to clipboard!',
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'top'
            });
        }).catch((error) => {
            console.error('Error copying to clipboard:', error);
            toast({
                title: 'Failed to copy to clipboard.',
                status: 'error',
                duration: 2000,
                isClosable: true,
                position: 'top'
            });
        });
    }
	useEffect(() => {
		// Ensure both balance and currentValuation are numbers before adding
		const profitLossValue = Number(amountInvested) - Number(currentValuation);
		setProfitLoss(profitLossValue.toFixed(2));
	  }, [amountInvested, currentValuation]);

	const portfolioTotalPages = Math.ceil((openPositions?.length || 0) / portfolioItemsPerPage);
	const portfolioCurrentItems = openPositions?.slice(
	(portfolioCurrentPage - 1) * portfolioItemsPerPage,
	portfolioCurrentPage * portfolioItemsPerPage
	) || [];
	  
	const handlePortfolioPageChange = (page) => {
	  setPortfolioCurrentPage(page);
	};

	 // Transaction Table Pagination
	 const transactionCurrentPage = 1;

	// Filter records before pagination
	const filteredRecords = records.map((record) => {
		const recordData = Array.isArray(record.record) ? record.record : record.record || {};
		const transactionHashData = recordData.transactionHash || recordData[4] || null;
		let transactionHash = transactionHashData;
	
		if (transactionHashData instanceof Uint8Array) {
			transactionHash =
				'0x' +
				Array.from(transactionHashData)
					.map((b) => b.toString(16).padStart(2, '0'))
					.join('');
		} else if (transactionHashData) {
			transactionHash = transactionHashData.toString();
		}
		
		// Determine the method based on transactionHash and direction
		let method;
		const isValidTransactionHash = transactionHash && typeof transactionHash === 'string' && transactionHash.startsWith('0x');
		if (isValidTransactionHash && recordData.direction == 0) {
			method = 'Deposit';
		} else if (isValidTransactionHash && recordData.direction == 1) {
			method = 'Withdraw';
		} else if (!isValidTransactionHash && recordData.direction == 0) {
			method = 'Close Position';
		} else if (!isValidTransactionHash && recordData.direction == 1) {
			method = 'Open Position';
		}
		// Return the original record along with the new method property
		return { ...record, method };
	});
	
	
	const recordsPerPage = 5;
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord);
	
	// Calculate total pages based on filtered records
	const transactionTotalPages = Math.ceil(filteredRecords.length / recordsPerPage);
	
	const handleTransactionPageChange = (page) => {
		setCurrentPage(page);
	};
  
    return (
        <div className='w-full lg:w-[1290px] h-auto lg:h-[734px] relative mt-8 lg:mt-[63px] ml-4 lg:ml-[111px]'>
			<div className='flex w-full sm:w-[200px] md:w-[230px] h-auto lg:h-[656px] flex-col gap-4 lg:gap-[18px] top-0 left-0'>
				<BalanceModal />
				<DepositModal />
			</div>

			{/* Portfolio Section */}
			<div className='flex flex-col gap-2 items-center w-full lg:w-[1040px] h-auto lg:h-[734px] p-2 bg-white rounded-lg border border-blue-200 absolute top-0 left-[250px] '>

				<div className='flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full'>
				<div className='flex gap-2 items-center'>
					<div className='w-4 h-3 bg-cover bg-no-repeat portfolio-icon'></div>
					<span className="text-lg md:text-xl lg:text-2xl font-bold text-blue-800">Portfolio</span>
				</div>
				<span className="text-xs text-gray-500">Your open positions.</span>
			</div>

		{/* Portfolio Table */}
		<table className="table-auto w-full">
			<thead className="bg-gray-200">
			<tr>
				<th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">Asset</th>
				<th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">Name</th>
				<th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">Open Date</th>
				<th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">Position</th>
				<th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">Purchased Amount</th>
				<th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">PnL</th>
				<th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">Action</th>
			</tr>
			</thead>
			<tbody>
			{portfolioCurrentItems && portfolioCurrentItems.length > 0 ? (
				portfolioCurrentItems.map((position, index) => {
				const perpetualIndex = parseInt(position.perpetualId, 10);
				const matchingPerpetual = perpetuals[perpetualIndex];
				//const etherValue = parseFloat(Web3.utils.fromWei(position.amount.toString(), 'ether')).toFixed(2);

				return (
					<tr key={index} className="border-t">
					<td className="px-2 lg:px-4 py-2 text-sm text-gray-600">{matchingPerpetual?.asset || 'N/A'}</td>
					<td className="px-2 lg:px-4 py-2 text-sm text-gray-600">{matchingPerpetual?.name || 'N/A'}</td>
					<td className="px-2 lg:px-4 py-2 text-sm text-gray-600">
						{position.purchaseTime
						? new Date(position.purchaseTime * 1000).toLocaleString('en-US', {
							month: '2-digit',
							day: '2-digit',
							year: '2-digit',
							hour: '2-digit',
							minute: '2-digit',
							second: '2-digit',
							hour12: false,
							})
						: 'N/A'}
					</td>
					<td className={`px-2 lg:px-4 py-2 text-sm ${position.isLong ? 'text-[#10b981]' : 'text-[#1e3a8a]'}`}>
						<span className={`${position.isLong ? 'long-icon' : 'short-icon'} inline-block mr-1`} aria-hidden="true"></span>
						{position.isLong ? 'Long' : 'Short'}
					</td>
					<td className="px-2 lg:px-4 py-2 text-sm text-gray-600">${parseFloat(Web3.utils.fromWei(position.amount.toString(), 'ether')).toFixed(2)}</td>
					<td className="px-2 lg:px-4 py-2 text-sm text-gray-600">
						{/* {profitLoss} */}
					</td>
					<td>
						<button
						className="flex w-[70px] lg:w-[90px] h-[40px] lg:h-[45px] justify-center items-center bg-[#D3D3D3] rounded-[24px] text-white"
						onClick={() => handleClosePositions(position, index)}
						>
						View
						</button>
					</td>
					</tr>
				);
				})
			) : (
				<tr>
				<td colSpan="6" className="px-2 lg:px-4 py-2 text-center text-sm text-gray-600">
					No open positions available.
				</td>
				</tr>
			)}
			</tbody>
		</table>
			{/* Portfolio Pagination */}
			<div className="flex justify-center mt-4">
				<button
					className="px-3 py-1 mx-1 bg-gray-300 rounded"
					disabled={portfolioCurrentPage === 1}
					onClick={() => handlePortfolioPageChange(portfolioCurrentPage - 1)}
				>
					Previous
				</button>
				{[...Array(portfolioTotalPages)].map((_, i) => (
					<button
					key={i}
					className={`px-3 py-1 mx-1 ${portfolioCurrentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
					onClick={() => handlePortfolioPageChange(i + 1)}
					>
					{i + 1}
					</button>
				))}
				<button
					className="px-3 py-1 mx-1 bg-gray-300 rounded"
					disabled={portfolioCurrentPage === portfolioTotalPages}
					onClick={() => handlePortfolioPageChange(portfolioCurrentPage + 1)}
				>
					Next
				</button>
			</div>
		</div>

		{/* Transaction History Section */}
		<div className='flex flex-col gap-2 items-center w-[1040px] h-[500px] p-2 bg-white rounded-lg border border-blue-200 absolute top-[550px] left-[250px] z-[100]'>
			<div className='flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full'>
					<div className='flex gap-2 items-center'>
						<div className='w-4 h-3 bg-cover bg-no-repeat transaction-icon'></div>
						<span className='text-2xl font-bold text-blue-800'>Transaction</span>
					</div>
					<span className='text-xs text-gray-500'>Perpetual futures available for trade.</span>
			</div>

				{/* Transaction Table */}
			<table className="table-auto w-full">
			<thead className="bg-gray-200">
				<tr>
				<th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">From</th>
				<th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">To</th>
				<th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Amount</th>
				<th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Method</th>
				<th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Transaction Hash</th>
				<th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Date</th>
				</tr>
			</thead>
			<tbody>
				
				{currentRecords.map((record, index) => {
					const recordData = Array.isArray(record.record) ? record.record : record.record || {};
					const fromAddr = recordData.addr || recordData[0] || 'N/A';
					const toAddr = recordData.token || recordData[1] || 'N/A';
					const amount = recordData.amount || recordData[2] || '0';
					const method = record.method;
					const transactionHash = recordData.transactionHash || recordData[4] || 'N/A';
					const timestamp = recordData.timestamp || recordData[5] || null;

					return (
					<tr key={index}>
						<td className="px-4 py-2 text-sm text-gray-700">
						{fromAddr !== 'N/A' ? (
							<>
							{fromAddr.slice(0, 6) + '...' + fromAddr.slice(-4)}
							<button onClick={() => copyToClipboard(fromAddr)} className="copy-icon">
							</button>
							</>
						) : (
							'N/A'
						)}
						</td>
						<td className="px-4 py-2 text-sm text-gray-700">
						{toAddr !== 'N/A' ? (
							<>
							{toAddr.slice(0, 6) + '...' + toAddr.slice(-4)}
							<button onClick={() => copyToClipboard(toAddr)} className="copy-icon">
							</button>
							</>
						) : (
							'N/A'
						)}
						</td>
						<td className="px-4 py-2 text-sm text-gray-700">
						${parseFloat(Web3.utils.fromWei(amount.toString(), 'ether')).toFixed(2)}
						</td>
						{/* <td className="px-4 py-2 text-sm text-gray-700">{direction === '0' ? 'Deposit' : 'Withdraw'}</td> */}
						<td className="px-4 py-2 text-sm text-gray-700">{method}</td>

						<td className="px-4 py-2 text-sm text-gray-700">
						<a
							href={`https://etherscan.io/tx/${transactionHash}`}
							style={{ color: 'blue', textDecoration: 'none' }}
							target="_blank"
							rel="noopener noreferrer"
						>
							{`${transactionHash.slice(0, 6)}...${transactionHash.slice(-4)}`}
						</a>
						</td>
						<td className="px-4 py-2 text-sm text-gray-700">
						{timestamp
							? new Date(timestamp * 1000).toLocaleString('en-US', {
								month: '2-digit',
								day: '2-digit',
								year: '2-digit',
								hour: '2-digit',
								minute: '2-digit',
								second: '2-digit',
								hour12: false,
							})
							: 'N/A'}
						</td>
					</tr>
					);
				})}
				</tbody>
			</table>
			{/* Transaction Pagination */}
			<div className="flex justify-center mt-4">
			<button
				className="px-3 py-1 mx-1 bg-gray-300 rounded"
				disabled={transactionCurrentPage === 1}
				onClick={() => handleTransactionPageChange(transactionCurrentPage - 1)}
			>
				Previous
			</button>
			{[...Array(transactionTotalPages)].map((_, i) => (
				<button
				key={i}
				className={`px-3 py-1 mx-1 ${transactionCurrentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
				onClick={() => handleTransactionPageChange(i + 1)}
				>
				{i + 1}
				</button>
			))}
			<button
				className="px-3 py-1 mx-1 bg-gray-300 rounded"
				disabled={transactionCurrentPage === transactionTotalPages}
				onClick={() => handleTransactionPageChange(transactionCurrentPage + 1)}
			>
				Next
			</button>
			</div>
		</div>
	</div>
    );          
}
