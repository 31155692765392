import { useEffect, useState } from 'react';
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { toast } from 'react-toastify';
import ProgressBarModal from './ProgressBarModal.js';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';

const config = require('./configs/tusd_abi.js');


export default function Promotions() {
    const [web3, setWeb3] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [contract, setContract] = useState(null);
    const [balance, setBalance] = useState(0);
    const [netBalance, setNetBalance] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [assetType, setAssetType] = useState('ETH');
    const [senderAddress, setSenderAddress] = useState('');
    const [amountUSD, setAmountUSD] = useState('');
    const [displayConvertedValue, setDisplayConvertedValue] = useState(null);
    const [transactionLink, setTransactionLink] = useState(null);
    const [jwtToken, setJwtToken] = useState();
    const [lastFetched, setLastFetched] = useState(null);


    const navigate = useNavigate();
    const PORTFOLIO_ADDRESS = config.portfolioAddress;
    const PORTFOLIO_ABI = config.portfolioAbi;
    const chainlinkTusdAddress = config.chainlinkTusdAddress;
    const chainlinkUsdtAddress = config.chainlinkUsdtAddress;
    const chainlinkEthAddress = config.chainlinkEthAddress;
    const chainlinkUsdcAddress = config.chainlinkUsdcAddress;
    const infuraKey = config.infuraKey;
    const chainlinkAbi = config.chainlinkAbi;
    const TUSD_ADDRESS = config.tUSDContractAddress;
    const TUSD_ABI = config.tUSDAbi;
    const USDC_ADDRESS = config.USDCContractAddress;
    const USDC_ABI = config.USDCAbi;
    const USDT_ADDRESS = config.USDTContractAddress;
    const USDT_ABI = config.usdtAbi;
    const USDC_RESERVE = config.USDCreserveAccount;
    const USDT_RESERVE = config.USDTreserveAccount;
    const ETH_RESERVE = config.ETHreserveAccount;
    const TUSD_RESERVE = config.TUSDreserveAccount;

    // Fetch JWT and initialize Web3
    const fetchJWT = () => {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
        window.location.href = '/sign';
        } else {
        setJwtToken(token);
        initWeb3();
        }
    };

    // Initialize Web3 and connect to MetaMask
    const initWeb3 = async () => {
        const provider = await detectEthereumProvider();
        if (provider) {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        const accounts = await web3Instance.eth.getAccounts();
        setAccounts(accounts[0]);
        setSenderAddress(accounts[0]);
        const contractInstance = new web3Instance.eth.Contract(PORTFOLIO_ABI, PORTFOLIO_ADDRESS);
        setContract(contractInstance);
        fetchBalance(web3Instance, accounts[0]); // Fetch initial balance
        if (accounts.length > 0 && contractInstance) {
            getBalance(contractInstance, accounts[0]);
        }
        } else {
        console.error('Please install MetaMask!');
        }
    };

    // Fetch the balance of the connected account
    const fetchBalance = async (web3Instance, account) => {
        try {
            let balanceValue;

            if (assetType === 'ETH') {
                // Fetch ETH balance
                const balanceWei = await web3Instance.eth.getBalance(account);
                balanceValue = web3.utils.fromWei(balanceWei, 'ether');
            } else if (assetType === 'USDT') {
                // Fetch USDT balance
                const usdtContract = new web3Instance.eth.Contract(USDT_ABI, USDT_ADDRESS);
                const usdtBalance = await usdtContract.methods.balanceOf(account).call();
                balanceValue = web3.utils.fromWei(usdtBalance, 'ether');
            } else if (assetType === 'USDC') {
                // Fetch USDC balance
                const usdcContract = new web3Instance.eth.Contract(USDC_ABI, USDC_ADDRESS);
                const usdcBalance = await usdcContract.methods.balanceOf(account).call();
                balanceValue = web3.utils.fromWei(usdcBalance, 'ether');
            }
            setBalance(balanceValue);
        } catch (error) {
            setBalance(null);
        }
    };

    useEffect(() => {
        if (web3 && senderAddress) {
            fetchBalance(web3, senderAddress); // Fetch initial balance based on selected asset
            const interval = setInterval(() => {
                fetchBalance(web3, senderAddress);
            }, 10000); // Refresh every 10 seconds

            // Cleanup interval on component unmount
            return () => clearInterval(interval);
        }
    }, [web3, senderAddress, assetType]); 

    useEffect(() => {
        fetchJWT();
    }, []);

    useEffect(() => {
        if (web3) {
            fetchPrices();
        }
    }, [web3]);

    const fetchPrices = async () => {
        let tenMinutes = 600000; // milliseconds
        const currentTime = new Date();
        if (lastFetched && currentTime - lastFetched < tenMinutes) {
            return;
        }
        const tokens = [
            { name: 'eth', address: chainlinkEthAddress },
            { name: 'tusd', address: chainlinkTusdAddress },
            { name: 'usdt', address: chainlinkUsdtAddress },
            { name: 'usdc', address: chainlinkUsdcAddress },
        ];

        try {
            const web3 = new Web3(new Web3.providers.HttpProvider(`https://mainnet.infura.io/v3/${infuraKey}`));

            const fetchPromises = tokens.map(async (token) => {
                const priceFeed = new web3.eth.Contract(chainlinkAbi, token.address);
                const roundData = await priceFeed.methods.latestRoundData().call();
                const price = web3.utils.fromWei(roundData.answer, 'ether') * 1e10;
                const setPriceFunctionName = `set${token.name.charAt(0).toUpperCase()}${token.name.slice(1).toLowerCase()}Price`;
                const setPriceFunction = window[setPriceFunctionName];
                if (typeof setPriceFunction === 'function') {
                    setPriceFunction(price);
                } else {
                    console.error(`Function ${setPriceFunctionName} not found on window.`);
                }

                return { token: token.name, price: price };
            });
        const results = await Promise.all(fetchPromises);
        setLastFetched(currentTime);
        } catch (error) {
        console.error('Failed to fetch prices:', error);
        }
    };

    const logDeposit = async (sender, recipient, amount, assetType) => {
        await fetch(`${config.serverURL}/api/deposit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sender: sender,
                recipient: recipient,
                amount: amount,
                assetType: assetType
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to log deposit');
            }
            return response.json();
        })
        .then(data => {
            console.log('Transaction logged:', data);
            setAmountUSD("");
        })
        .catch(error => console.error('Error logging transaction:', error));
    };

    const sendAssets = async () => {
    if (!web3) {
        console.log('Web3 is not initialized. Click "Connect Wallet" first.');
        return;
    }

    const accounts = await web3.eth.getAccounts();
    if (accounts.length === 0) {
        console.error("No accounts found. Make sure MetaMask is connected.");
        return;
    }

    const numericAmountUSD = parseFloat(amountUSD);
    if (isNaN(numericAmountUSD) || numericAmountUSD <= 0) {
        console.error("Invalid amount. Please enter a valid number.");
        return;
    }
    
    let amountToSend;
    let amountInTokens;
    setIsModalOpen(true); 

    try {
        if (assetType === 'ETH') {
                if (!window.ethPrice) {
                    console.error("ETH price not available. Cannot proceed with transaction.");
                    return;
                }
                //amountToSend = web3.utils.toWei((amountUSD / window.ethPrice).toFixed(18), 'ether');
                amountInTokens = amountUSD / window.ethPrice;
                amountToSend = web3.utils.toWei((amountUSD / window.ethPrice).toFixed(18), 'ether');
            }else if (assetType === 'USDT') {
                amountInTokens = amountUSD / window.usdtPrice;  // This assumes you have fetched the correct tusdPrice
                amountToSend = web3.utils.toWei((amountUSD / window.usdtPrice).toFixed(18), 'ether');
            } else if (assetType === 'USDC') {
                amountInTokens = amountUSD / window.usdcPrice;  // This assumes you have fetched the correct tusdPrice
                amountToSend = web3.utils.toWei((amountUSD / window.usdcPrice).toFixed(18), 'ether');
            }

            const nonce = await web3.eth.getTransactionCount(senderAddress);
            console.log(`Current nonce: ${nonce}`);

        switch (assetType) {
        case 'ETH':
                console.log('ETH Values ', senderAddress, ETH_RESERVE, amountToSend, amountInTokens);
                try {
                web3.eth.sendTransaction({
                from: senderAddress,
                to: ETH_RESERVE,
                value: amountToSend,
                gas: 21000,
                nonce: nonce
                })
                .on('transactionHash', hash => {
                console.log(`Transaction hash: ${hash}`);
                const link = `https://etherscan.io/tx/${hash}`;
                setTransactionLink(link);
                setProgress(30);
                toast.info("Transaction pending...");
                })
                .on('receipt', async (receipt) => {
                try {
                    setProgress(50);
                    const response = await fetch(`${config.serverURL}/api/contract/add_record`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        token: ETH_RESERVE,
                        address: senderAddress,
                        amount: amountUSD,
                        direction: '0',
                        transactionHash: receipt.transactionHash,
                    }),
                    });
                
                    if (!response.ok) {
                    throw new Error(`Error adding record: ${response.statusText}`);
                    }
                
                    const data = await response.json();
                    console.log('Data check ', data);
                    setAmountUSD("");
                    claimBonus();
                    setProgress(100);
                    setIsModalOpen(false);
                } catch (error) {
                    console.error('Failed to add record to the server:', error);
                    setProgress(0);
                    setIsModalOpen(false);
                }
                }) .on('error', error => {
                console.error(`Transaction failed: ${error}`);
                setIsModalOpen(false); // Close modal on error
                });
            } catch (error) {
                console.error('Error sending ETH transaction:', error);
                setIsModalOpen(false); // Close modal on error
            }
            break;
        case 'USDT':
            sendToken(USDT_ADDRESS, USDT_ABI, amountToSend, senderAddress, assetType, amountInTokens, USDT_RESERVE);
            break;
        case 'USDC':
            sendToken(USDC_ADDRESS, USDC_ABI, amountToSend, senderAddress, assetType, amountInTokens, USDC_RESERVE);
            break;
        default:
            console.log('Asset type not supported');
            setIsModalOpen(false); // Close modal if unsupported asset
        }
    } catch(error) {
        console.error('Error in transaction:', error);
        setIsModalOpen(false); // Ensure modal is closed on error
    }
    };

    const sendToken = async (tokenAddress, tokenABI, amountToSend, senderAddress, assetType, amountInTokens, tokenReserve ) => {
        const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
        setIsLoading(true);
        
        console.log(`Send amount ${amountToSend}`);
        await tokenContract.methods.transfer(tokenReserve, amountToSend).send({ from: senderAddress })
            .on('transactionHash', hash => {
                console.log(`Transaction hash: ${hash}`);
                const link = `https://etherscan.io/tx/${hash}`;
                setTransactionLink(link);
                setProgress(30);
                toast.info("Transaction pending...");
            })
            .on('receipt', async receipt => {
            console.log("Response " + tokenAddress + ' ' + senderAddress + ' ' + amountToSend + ' ' + receipt.transactionHash);    
                try {
                setProgress(50);
                    await fetch(`${config.serverURL}/api/contract/add_record`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: tokenAddress,
                            address: senderAddress,
                            amount: amountUSD,
                            direction: '0',
                            transactionHash: receipt.transactionHash,
                            assetType : assetType
                        }),
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Error adding record: ${response.statusText}`);
                        }
                        claimBonus();
                        logDeposit(senderAddress, tokenAddress, amountInTokens, assetType);
                        console.log('Response', response);
                        return response.json();
                    })
                    .then(data => {
                        console.log('Data check ', data);
                        setAmountUSD("");
                        setProgress(100);
                        setIsModalOpen(false);
                    })
                    .catch(error => console.error('Error logging transaction:', error));
                    setProgress(0);
                    setIsModalOpen(false);
                } catch (error) {
                    console.error(`Error adding record: ${error.message}`);
                    setProgress(0);
                    setIsModalOpen(false); // Close modal on error
                }
                console.log('Transaction receipt',receipt);
            })
            .on('error', error => {
                console.error(error);
                setIsModalOpen(false); // Close modal on error
            });
    };


    const getPriceFromWindow = () => {
        switch (assetType) {
        case 'ETH':
            return window.ethPrice;
        case 'USDT':
            return window.usdtPrice;
        case 'USDC':
            return window.usdcPrice;
        default:
            return 0;
        }
    };

    const handleAssetTypeChange = (e) => {
        setAssetType(e.target.value);
    };

    window.setEthPrice = (price) => {
        window.ethPrice = price;
    };

    window.setUsdtPrice = (price) => {
        window.usdtPrice = price;
    };

    window.setUsdcPrice = (price) => {
        window.usdcPrice = price;
    };

    window.setTusdPrice = (price) => {
        window.tusdPrice = price;
    };

    const calculateConvertedValue = () => {
        const price = getPriceFromWindow();
        if (price && amountUSD) {
        const convertedValue = amountUSD / price;
        setDisplayConvertedValue(convertedValue);
        } else {
        setDisplayConvertedValue(null);
        }
    };

    useEffect(() => {
        calculateConvertedValue();
    }, [assetType, amountUSD]);


    const getBalance = async (contractInstance, account) => {
        try {
          const balance = await contractInstance.methods.getNetBalance(account).call();
          setNetBalance(parseFloat(Web3.utils.fromWei(balance.toString(), "ether")).toFixed(2)); 
          console.log('balance ', balance);
        } catch (error) {
          console.error("Error getting balance:", error);
        }
      };

    const claimBonus = async () => {
        const promotions = await getPromotions(accounts);
        const convertBalance = parseFloat(netBalance);
        if (convertBalance >= 50 && promotions.length === 0 || amountUSD >= 50 && promotions.length === 0) {
            console.log('Yes, eligible for bonus:', 100);
            await sendPromotions(TUSD_RESERVE, senderAddress, 100);
        } else if(convertBalance < 50 && amountUSD < 50) {
            toast.error("Please deposit the minimum $50.");
        }else {
            toast.error("Not eligble to receive bonus.");
            await new Promise(resolve => setTimeout(resolve, 5000));
            navigate("/perpetuals"); 
        }
    };

    const logPromotions = async (walletAddress, accountBalance) => {
        await fetch(`${config.serverURL}/api/promotions/log`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                walletAddress: walletAddress,
                ipAddress:'0.0.0.0',
                accountBalance: accountBalance,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to log deposit');
            }
            return response.json();
        })
        .then(data => {
            console.log('Transaction logged:', data);
        })
        .catch(error => console.error('Error logging transaction:', error));
    };

    const getPromotions = async (walletAddress) => {
        try {
            const response = await fetch(`${config.serverURL}/api/promotions?walletAddress=${encodeURIComponent(walletAddress)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch promotions');
            }
            const promotions = await response.json();
            console.log('Fetched promotions:', promotions);
            return promotions;
        } catch (error) {
            console.error('Error fetching promotions:', error);
            return [];
        }
    };

    const generateRandomTransactionHash = () => {
        // Generate random 32 bytes and convert to a hexadecimal string
        const randomHash = Web3.utils.randomHex(32);
        return randomHash;
      };
    
    const sendPromotions = async (token, address, amount) => {
        setIsModalOpen(true);
        setProgress(30);
        await fetch(`${config.serverURL}/api/contract/add_record`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: token,
                address: address,
                amount: amount,
                direction: '0',
                transactionHash: generateRandomTransactionHash(),
                assetType : assetType
            }),
        })
        .then(response => {
            if (response.status === 100) {
                throw new Error('Failed to send promotion');
            } else if(response.status === 200) {
                logPromotions(accounts, netBalance);
                setProgress(100);
                toast.success("Transaction confirmed!");
                setIsModalOpen(false);
                return new Promise(resolve => setTimeout(resolve, 3000));
            }
            return response.json();
        })
        .then(() => {
            navigate("/perpetuals"); 
        })
        .catch(error => console.error('Error sending promotion:', error));
        setIsModalOpen(false);
        setProgress(0);
    };
    return (
        <div className="flex flex-row items-start p-4 space-x-6">
            <div className="max-w-xs w-full">
                <div className="flex justify-center items-center gap-2 w-full h-[52px] bg-gray-100 rounded-2xl">
                <div className='deposit-icon'/>
                <span className="font-bold text-gray-500 text-xl md:text-2xl">
                    Deposit
                </span>
            </div>

            {/* Main Content */}
            <div className="flex flex-col gap-[12px] items-center">
                {/* Asset Selection */}
                <div className="flex flex-col gap-1 items-start w-full">
                <label htmlFor="asset" className="text-sm md:text-base">
                    Asset&nbsp;
            <button data-tip="Select Asset to Deposit" id="myButton" className="tooltip-icon"></button>
            <ReactTooltip anchorId="myButton" />
                </label>
                <select
                    id="asset"
                    value={assetType}
                    onChange={handleAssetTypeChange}
                    className="w-full p-2 border border-gray-300 rounded-md text-sm md:text-base"
                >
                    <option value="ETH">Ethereum (ETH)</option>
                    <option value="USDT">Tether (USDT)</option>
                    <option value="USDC">USD Coin (USDC)</option>
                </select>
                
                <p className="text-sm text-gray-500">
                    Current Price: ${getPriceFromWindow() ? getPriceFromWindow().toFixed(2) : 'Loading...'}&nbsp;
                    <button data-tip="Current Value of Token Selected" id="myButton" className="tooltip-icon"></button>
                    <ReactTooltip anchorId="myButton" />
                </p>
                </div>

                {/* Available Balance */}
                <div className="flex flex-col gap-1 items-start w-full">
                    <span className="text-xs md:text-sm text-gray-500">
                        Available to Deposit&nbsp;
                    <button data-tip="Total Amount of Tokens in Wallet" id="myButton" className="tooltip-icon"></button>
                        <ReactTooltip anchorId="myButton" />
                    </span>
                    <span className="text-sm md:text-base font-bold text-gray-400">
                        {balance !== null ? `${parseFloat(balance).toFixed(4)} ${assetType}` : 'Loading...'}
                    </span>
                </div>

                {/* Deposit Amount */}
                <div className="flex flex-col gap-1 items-start w-full">
                    <span className="text-xs md:text-sm text-gray-500">
                    Enter Deposit Amount (tUSD)&nbsp;
                    <button data-tip="Deposit Amount in converted tUSD Value" id="myButton" className="tooltip-icon"></button>
                                <ReactTooltip anchorId="myButton" />
                    </span>
                    
                    <div className="flex items-center gap-2 w-full border border-gray-500 rounded-md p-2">
                        <input
                        type="number"
                        id="amount"
                        value={amountUSD}
                        onChange={(e) => setAmountUSD(e.target.value)}
                        placeholder="Amount"
                        className="w-full text-sm text-base "
                        />
                    </div>
                </div>

                {/* Converted Value */}
                <div className="w-full text-xs md:text-base">
                    <span className="truncate w-full overflow-hidden text-ellipsis">
                        {displayConvertedValue !== null ? (
                        <>
                            Converted Value:
                            <span className="block truncate">
                                {displayConvertedValue.toFixed(5)}
                            </span>
                            <span>
                                {assetType}
                            </span>
                        </>
                        ) : ''}
                    </span>
                </div>


                {/* Deposit Button */}
                <button
                    className="flex items-center justify-center gap-2 w-full h-[52px] bg-white rounded-2xl border border-gray-500 mt-4 hover:bg-gray-100"
                    onClick={sendAssets}
                >
                    <span className="font-bold text-gray-500 text-sm md:text-base">
                        Deposit
                    </span>
                </button>
                <ProgressBarModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    progress={progress}
                    transactionLink={transactionLink}
                />
            </div>
            </div>

            <div className="flex flex-col items-center space-y-6 max-w-lg w-full">
            {/* Promotion Banner */}
            <div className="bg-blue-600 text-white rounded-lg shadow-md p-6 max-w-lg w-full text-center">
                <h1 className="text-3xl font-bold mb-4">Get Your 100 tUSD Bonus!</h1>
                <p className="text-lg mb-2">
                Deposit a minimum of $50 equivalent in ETH, USDT, or USDC to claim your bonus.
                </p>
                <p className="text-sm">*Terms and conditions apply.</p>
            </div>

            {/* Promotion Details */}
            <div className="bg-white rounded-lg shadow-md p-6 mt-6 max-w-lg w-full text-left">
                <h2 className="text-xl font-bold mb-4">Promotion Details</h2>
                <ul className="list-disc pl-5 space-y-2">
                <li>Offer: Receive 100 tUSD as a bonus.</li>
                <li>Minimum deposit: $50 equivalent in ETH, USDT, or USDC.</li>
                <li>
                    Terms: The bonus can only be claimed once per user. Additional conditions may apply.
                </li>
                </ul>
            </div>
        </div>
    </div>
    )
};

